import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        appName:'PAX',
        appUrl: 'https://pax.inmunocel.com',
        appVersion: '0.0.7',
        activeTab:'/',
        loginOpened: true,
        profile_userInfo: {},
        misprivx:{},
        defsPrivx:null,
        loadingAvatar:'../static/img/ripple.gif',
        profile_defaultAvatar: '../static/img/default_original.png',
        tempOldAvatar:'',
    },
    getters: {
        myAvatar: state => {
                return (state.profile_userInfo && state.profile_userInfo.photoURL) ? state.profile_userInfo.photoURL : state.profile_defaultAvatar;
        },
        myName: state=>{
            return state.profile_userInfo.displayName || 'Indique su nombre';
        },
    },
    mutations: {
        setWhatTo: (state,payload)=>{
            state[payload.what] = payload.to;
        },
        setObjTo:(state,payload)=>{
            state[payload.what]=Object.assign({},payload.to);
        },
        pushToArray: (state,payload) => {
            state[payload.array].push(payload.value);
        },
        removeFromArray: (state,payload)=>{
            let indiexist = state[payload.array].findIndex(unitem => unitem[payload.attr]==payload.value);
            if(indiexist!=-1){
                state[payload.array].splice(indiexist,1);
            }
        },
        updateMyAvatar: (state,avatarURL)=>{
            state.profile_userInfo.photoURL=avatarURL;
        },
        //Editar array
        editArray(state, payload){
            const index = state[payload.array].findIndex((item) => item[payload.attr] === payload.value);
            if(index !== -1){
                state[payload.array].splice(index, 1, payload.updatedValue);
            }
        },
        //eliminar institución sólo front
        removeFromArrayByReference: (state, payload) => {
            let index = state.allInstitutions.indexOf(payload);
            if(index != -1){
                state.allInstitutions.splice(index, 1);
            }
        }
    },
    actions:{

    }
})