<template>
  <f7-page name="profile">
      <f7-navbar title="Mi Perfil" back-link="Back"></f7-navbar>
   <f7-list class="no-margin">
     <f7-list-item 
    divider
    class="cendivider"
     :title="profile_userInfo.email"
      >
      </f7-list-item>
      <input type="file" id="myavtr-input" @change="filesChange($event)" accept="image/*" />
      <f7-list-item 
     @click="editarNombre"
     header="Nombre"
     :title="profile_userInfo.displayName"
     link="#"
      >   
      <span slot="footer" v-if="myName=='Indique su nombre'" class="text-color-red"><f7-icon  material="error_outline"></f7-icon> Indique su nombre</span>

      <f7-icon slot="after" material="create"></f7-icon>
      </f7-list-item>
     <f7-list-item class="userprofile"
     title="Avatar"
     link="#"
     @click="tomarFoto()"
      >  <img  slot="media" :src="myAvatar" alt="avatar user"> 
      <span slot="footer" v-if="myAvatar==defaultAvatar" class="text-color-red"><f7-icon  material="error_outline"></f7-icon> Sube tu foto</span>
      <f7-icon  slot="after" material="camera_alt"></f7-icon>
      </f7-list-item>
  
  
   </f7-list>
  </f7-page>
</template>
<script>
  import {mapState,mapGetters} from 'vuex';
import {photoMixin} from '../mixins/photomixin';

export default {
   mixins: [photoMixin],
data() {
      return {
        moMail: true,
        moName: true
      }

},
computed:{
  ...mapState(['profile_userInfo','defaultAvatar']),
  ...mapGetters(['myAvatar','myName'])
},
mounted(){
 
},
methods:{
  editarNombre(){
this.$f7.dialog.prompt('Indique su nombre',  (nombre)=> {
  let fixedNombre=nombre.trim();
  if(fixedNombre==""){
    return this.$f7.dialog.alert('Debes indicar un nombre');
  }
  this.$f7.dialog.preloader('Guardando');
    this.$firebase.auth().currentUser.updateProfile({
      displayName: nombre
    }).then(()=> {
      // Update successful.
      this.profile_userInfo.displayName=nombre;
      this.$f7.dialog.close();
    }).catch((error)=> {
      // An error happened.
       this.$f7.dialog.close();
      this.$f7.dialog.alert(error.message);
    });
  },
()=>{}
  ,
    (this.profile_userInfo.displayName!='Indique su nombre'?this.profile_userInfo.displayName:null)

  );
  }
}
}
</script>
<style >
.userprofile img{
  width:50px;
  height: 50px;
border-radius:50%;
}
#myavtr-input{
  display: none;
}
.cendivider span{
  margin: 0 auto;
    font-weight: bold;
}
</style>



