<template>
  <f7-page name="congrio">
      <f7-navbar title="Licencia de Usuario" subtitle="Acuerdo de licencia de usuario final PAX" back-link="Back"></f7-navbar>

<f7-block-title>ALUFE V.1.0.0</f7-block-title>
   <f7-block class="text-align-center">Disclaimer legal aqui</f7-block>

  </f7-page>
</template>
<script>
  import {mapState} from 'vuex';
 import logoinmuno from '../static/icons/152x152.png';

export default {
data() {
      return {
        logoinmuno
      }

},
computed:{
   ...mapState(['appVersion'])
},
methods:{

}
}
</script>
<style >
.logourgne{
  max-width: 50%;
    border-radius: 50%;
}
</style>



