// Import Vue
import Vue from 'vue';

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';


// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';

// Import App Component
import App from '../components/app.vue';

//**** FIREBASE ****///
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';
import { fireBaseConfig } from '../helpers/firebaseConfig';
firebase.initializeApp(fireBaseConfig);
Vue.prototype.$firebase = firebase;
//**** Moment ****///
import moment from 'moment';
moment.locale('es');
Vue.prototype.$moment = moment;
//**** Store ****///
import { store } from '../store/store';
//*** LOCAL FORAGE */
import localForage from "localforage";
Vue.prototype.$localforage=localForage;

//**** Font ****///
import 'typeface-titillium-web';
// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init App
new Vue({
  el: '#app',
  render: (h) => h(App),
  store,
  // Register App Component
  components: {
    app: App
  },
});