<template>
  <f7-page name="congrio">
      <f7-navbar title="InmunoPAX" :subtitle="'versión '+appVersion" back-link="Back"></f7-navbar>
    <f7-block class="text-align-center"><img class="logourgne" :src="logoinmuno"/></f7-block>

   <f7-block class="text-align-center"><b>InmunoPAX</b> es un servicio desarrollado por HICAPPS SpA. <br/><br/>Para mayor información, contacte a info@hicapps.cl</f7-block>

  </f7-page>
</template>
<script>
  import {mapState} from 'vuex';
 import logoinmuno from '../static/icons/152x152.png';

export default {
data() {
      return {
        logoinmuno
      }

},
computed:{
   ...mapState(['appVersion'])
},
methods:{

}
}
</script>
<style >
.logourgne{
  max-width: 50%;
    border-radius: 50%;
}
</style>



