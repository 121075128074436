<template>
  <f7-page name="home" :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :sliding="false" large>
       <!-- <f7-nav-left>
        <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
      </f7-nav-left> -->
      <f7-nav-title sliding>PAX</f7-nav-title>
      <f7-nav-right>
        <f7-link panel-open="right" icon-only>
        <f7-icon material="notifications" >
          <f7-badge color="gray">0</f7-badge>
        </f7-icon>
      </f7-link>
      </f7-nav-right>
      <f7-nav-title-large sliding>PAX</f7-nav-title-large>
    </f7-navbar>


    <f7-toolbar tabbar labels bottom>
      <f7-link 
        tab-link="#hometab" 
        tab-link-active  
        text="Inicio" 
        icon-material="home">
      </f7-link>
      <f7-link 
        tab-link="#tablabs" 
        text="Exámenes" 
        icon-material="view_list"
      ></f7-link>
       <f7-link tab-link="#tabmenu">
      <f7-icon material="menu">
        <f7-badge v-if="(myAvatar==defaultAvatar || myName=='Indique su nombre')" color="red">1</f7-badge>
      </f7-icon>
      <span class="tabbar-label">Config.</span>
    </f7-link>
    </f7-toolbar>

    <f7-tabs swipeable>
      <f7-tab id="hometab" class="page-content" tab-active>
        <!-- Page content-->
          <f7-block strong>
            <p>Bienvenido a InmunoPAX, su portal de exámenes de Inmunocel.</p>

            <p>Aquī podrá encontrar siempre sus resultados históricos de exáemenes, cada vez que los necesite, en un formato amigable y accesible.</p>
          </f7-block>
      </f7-tab>

      <f7-tab id="tablabs" class="page-content">
           <f7-block>Ud no tiene examenes registrados en Inmunocel.</f7-block>

      </f7-tab>
      <f7-tab id="tabmenu" class="page-content">
       <f7-list class="no-margin ikonfix tealikon">
          <f7-list-item divider>Menu</f7-list-item>
          <f7-list-item
            v-for="unmenu in elmenu"
            :key="unmenu.link"
            :title="unmenu.title"
            :footer="unmenu.subtitle"
            @click="gotoMenu(unmenu.link)"
            link="#">
            <f7-icon  slot="media" :material="unmenu.ikon"></f7-icon>
            <f7-icon v-if="unmenu.avtrAlertIkon && (myAvatar==defaultAvatar || myName=='Indique su nombre' )" class="text-color-red"  slot="after" :material="unmenu.avtrAlertIkon"></f7-icon>
          </f7-list-item>
      
        </f7-list>

        <f7-block class="alfondodelabismo">
          <f7-button raised fill @click="logout()">Cerrar sesión</f7-button>
        </f7-block>
      </f7-tab>
    </f7-tabs>


  </f7-page>
</template>

<script>

import {mapState, mapGetters} from 'vuex';

export default {
  data(){
    return {
       elmenu:[{
       title:'Mi Perfil',
       subtitle:'Personalice su nombre, Avatar',
        ikon:'account_circle',
        link:'/pertil/',
        avtrAlertIkon: 'error_outline'
      },
      {
       title:'Acuerdo de uso',
        subtitle:'Licencia de usuario de PAX',
        ikon:'assignment',
        link:'/agreement/',
      },
      {
      title:'About',
        subtitle:'sobre PAX',
        ikon:'info',
        link:'/about/',
      }],
    }
  },
  computed:{
    ...mapState(['defaultAvatar','privx']),
     ...mapGetters(['myAvatar','myName'])
  },
  methods:{
     gotoMenu(url){
        console.log('navengado a '+url);
            this.$f7.views.main.router.navigate(url);
      },
       logout() {
        console.log('Logging out');
        this.$f7.dialog.preloader('Cerrando sesión...');
        this.$firebase.auth().signOut().then(()=> {
          console.log('Sign-out successful.');
         // this.$localforage.setItem('privx', null);
         // this.$localforage.setItem('focusedEventId', null);
          this.$store.commit('setObjTo',{what:'misprivx',to:{lm:-1}});
          this.$f7.dialog.close();
        }).catch((error)=> {
          this.$f7.dialog.close();
          console.log('Sign-out error.', error);
        });
      }
  }
}
</script>