<template>
<f7-app :params="f7params" theme-dark>

  <!-- Left panel with cover effect when hidden -->
  <f7-panel v-if="false" left cover theme-dark :visible-breakpoint="960">
    <f7-view>
      <f7-page>
        <f7-navbar title="Left Panel"></f7-navbar>
        <f7-block-title>Left View Navigation</f7-block-title>
        <f7-list>
          <f7-list-item link="/left-page-1/" title="Left Page 1"></f7-list-item>
          <f7-list-item link="/left-page-2/" title="Left Page 2"></f7-list-item>
        </f7-list>
        <f7-block-title>Control Main View</f7-block-title>
        <f7-list>
          <f7-list-item link="/about/" view=".view-main" panel-close title="About"></f7-list-item>
          <f7-list-item link="/form/" view=".view-main" panel-close title="Form"></f7-list-item>
          <f7-list-item link="#" view=".view-main" back panel-close title="Back in history"></f7-list-item>
        </f7-list>
      </f7-page>
    </f7-view>
  </f7-panel>


 <!-- Right panel with reveal effect-->
  <f7-panel right reveal theme-dark>
    <f7-view>
      <f7-page>
        <f7-navbar title="Notificaciones"></f7-navbar>
        <f7-block>Ud. no tiene nuevas notificaciones</f7-block>
      </f7-page>
    </f7-view>
  </f7-panel>


  <!-- Your main view, should have "view-main" class -->
  <f7-view main class="safe-areas" url="/"></f7-view>


  <!-- Popup -->
  <f7-popup id="my-popup">
    <f7-view>
      <f7-page>
        <f7-navbar title="Popup">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <p>Popup content goes here.</p>
        </f7-block>
      </f7-page>
    </f7-view>
  </f7-popup>

 <f7-login-screen id="my-login-screen" :opened="loginOpened">
    <f7-view>
      <f7-page login-screen>
      <f7-block class="text-align-center no-margin-top no-margin-bottom">
          <img class="loginlogo" :src="logoinmuno" />
        </f7-block>
        <f7-login-screen-title class="no-margin-top grayoporte">PAX <span class="lesmall">v {{appVersion}}</span></f7-login-screen-title>
          <f7-block v-if="stepLogin==1" class="text-align-center no-margin-bottom fraselogin"> {{fraseLogin(stepLogin)}}</f7-block>
   <f7-list class="no-margin-top no-margin-bottom loginputs " inset>
    <f7-list-item v-if="stepLogin>1" class="bakgris" header="Ingresando como:" :title="elusuario">
      <f7-link  @click="reinitiflujologin()" slot="after"><f7-icon material="settings_backup_restore" class="text-color-white" ></f7-icon></f7-link>
    </f7-list-item>
        <f7-list-input
        id="paxmail"
        v-if="stepLogin==1"
        outline
        class="text-color-white"
    label="email"
    type="email"
    name="paxmail"
    placeholder="usuario@email.com"
     :value="elusuario"
    @input="elusuario = $event.target.value"
     @keyup.native.enter="avanzaLogin()"
    clear-button
  >
  </f7-list-input>
  <f7-list-input
  v-else
  outline
  id="paxpass"
  class="text-color-white laveldelpass"
    :label="fraseLogin(stepLogin)"
    name="lepasswrd"
    type="password"
    placeholder="contraseña"
      :value="elpassword"
      @keyup.native.enter="stepLogin==2?ingresarConemailpass():crearCuenta()"
    @input="elpassword = $event.target.value"
    clear-button
  >
  </f7-list-input>
      </f7-list>

<f7-block class="no-margin-bottom">
  <f7-row v-if="stepLogin==1" >
    <f7-col></f7-col>
     <f7-col>
       <f7-button class="bold" outline large raised @click="avanzaLogin()">Siguiente <f7-icon material="chevron_right"></f7-icon></f7-button>
       </f7-col>
  </f7-row>
             <f7-button  v-else-if="stepLogin==2" class="bold" large raised outline @click="ingresarConemailpass()">Ingresar</f7-button>
              <f7-button  v-else-if="stepLogin==3" class="bold" large raised outline @click="crearCuenta()">Crear nueva cuenta</f7-button>
      </f7-block>

<f7-block v-if="showForgotPass" class="no-margin-bottom">
    <f7-button class="bg-color-white" large raised outline @click="forgotMyPass()">Olvidé mi contraseña!</f7-button>
</f7-block>
        <f7-list>
          <f7-block-footer class="foneleven">
            Inmunocel PAX &copy; {{$moment().format('YYYY')}}<br>Powered by HICAPPS
          </f7-block-footer>
        </f7-list>
      </f7-page>
    </f7-view>
  </f7-login-screen>
</f7-app>
</template>
<script>
  import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
  import cordovaApp from '../js/cordova-app.js';
  import routes from '../js/routes.js';
 import logoinmuno from '../static/img/logoinmuno.png';
import { mapState } from 'vuex';
  export default {
    data() {
      return {
        // Framework7 Parameters
        f7params: {
          id: 'cl.hicapps.inmunopax', // App bundle ID
          name: 'PAX', // App name
          theme: 'md', // Automatic theme detection


          // App routes
          routes: routes,


          // Input settings
          input: {
            scrollIntoViewOnFocus: Device.cordova && !Device.electron,
            scrollIntoViewCentered: Device.cordova && !Device.electron,
          },
          // Cordova Statusbar settings
          statusbar: {
            iosOverlaysWebView: true,
            androidOverlaysWebView: false,
          },
        },

       // Login screen data
        observaMyPrivx: null,
        logoinmuno,
         elusuario: null,
        elpassword: null,
        stepLogin: 1,
        showForgotPass: false,
        navigatedAway: false,
      }
    },
         computed: {
   ...mapState(['appVersion','loginOpened','appName','activeTab','profile_userInfo','misprivx','defsPrivx']),
        },
    methods: {
      crearCuenta(){
        if(!this.elusuario || !this.elpassword){
          return this.$f7.dialog.alert('Debe ingresar un email y un password');
        }
        this.$f7.dialog.preloader('Creando...');
        this.$firebase.auth().createUserWithEmailAndPassword(this.elusuario, this.elpassword).catch((error)=> {
          this.$f7.dialog.close();
          if(error.code=='auth/email-already-in-use'){
            this.ingresarConemailpass();
          }
          else{
            console.log('error al crear');
            if(error.code=='auth/weak-password'){
              error.message='La contraseña debe ser de al menos 6 caracteres.'
            }
            this.$f7.dialog.alert(error.message,error.code);
          }
        });
      },
           fraseLogin(a){
        if(a==1){
          return 'Ingrese su email';
        }
        else if(a==2){
          return 'Ingrese su contraseña';
        }
        else if(a==3){
          return 'Cree una nueva contraseña';
        }
      },
       emailIsValid (email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
      },
       avanzaLogin(){
        //chequear si este email existe
        if(!this.elusuario){
          return this.$f7.dialog.alert('Debe ingresar un email','Falta email');
        }
        if(!this.emailIsValid(this.elusuario)){
          return this.$f7.dialog.alert('Debe ingresar un email tipo USUARIO@DOMINIO.XYZ','Formato email no válido');
        }
        this.$localforage.setItem('prevUser', this.elusuario);
          this.$f7.dialog.preloader('Verificando...');
        this.versiExisto();
      },
         versiExisto(){
        let doiexist = this.$firebase.functions().httpsCallable('doiexist');
        doiexist({email:this.elusuario}).then((ladata)=> {
          this.$f7.dialog.close();
          console.log('llego data',ladata);
          if(ladata.data){
            //usuario ya existia, hay que pedirle su password
            this.stepLogin=2;
          }
          else{
            //usuario no existia, pedirle que cree su password
            this.stepLogin=3;
          }
           this.$$('#paxpass').find('input').focus();
          this.$localforage.setItem('prevUser', this.elusuario);
        }).catch((error)=>{
          console.log('error en fx',error);
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message,'Error');
        });
      },
      async proceedToLoginFlow(user){
        try {
        let hola = await this.$firebase.database().ref('privx/'+user.uid).once('value');
        
        let susprivx=hola.val();
          if(susprivx){
              this.$store.commit('setObjectTo',{what:'misprivx',to:susprivx});
          }
          console.log('susprivx',susprivx);
          this.$f7.dialog.close();
            this.$store.commit('setWhatTo',{what:'loginOpened',to:false});
        }
        catch(error) {
          this.$f7.dialog.alert(error.message,error.code);
        }
      },
      ingresarConemailpass(){
        if(!this.elusuario || !this.elpassword){
          return this.$f7.dialog.alert('Debe ingresar un email y un password');
        }
        this.$f7.dialog.preloader('Ingresando...');
        return this.$firebase.auth().signInWithEmailAndPassword(this.elusuario, this.elpassword).catch((error)=> {
                this.$f7.dialog.close();
                 if(error.code=='auth/wrong-password'){
                    this.$f7.dialog.alert('Combinación de email/password no existe','Error de Auth');
                     //ENCENDER OLVIO DU SPASSWORD
                    this.showForgotPass=true;
                }
                else{
                  console.log('error al ingresar');
                 this.$f7.dialog.alert(error.message,error.code);
                }
            });
      },
    },
    mounted() {
      this.$f7ready((f7) => {
        // Init cordova APIs (see cordova-app.js)
        if (Device.cordova) {
          cordovaApp.init(f7);
        }
        // Call F7 APIs here
        this.$f7.dialog.preloader('Inicializando...');
           //iniciar chequeo status login
        this.$firebase.auth().onAuthStateChanged((user)=> {
          
          if (user) {
             if(!user.emailVerified){
            return user.sendEmailVerification().then(()=> {
              // Email sent.
               this.$f7.dialog.close();
              return this.$f7.dialog.alert('Revise su bandeja de entrada de '+this.elusuario+' para validar su cuenta antes de poder ingresar.','Validar',()=>{
                   return this.$firebase.auth().signOut();
              });
            }).catch((error)=> {
              // An error happened.
               this.$f7.dialog.close();
              this.$f7.dialog.alert(error.message,error.code);
            });
          }
          else{
            this.showForgotPass=false;
            // User is signed in.
            console.log('welcome',user);
            let profile_userInfo={
              displayName: user.displayName,
              email: user.email,
               emailVerified: user.emailVerified,
              photoURL: user.photoURL,
              phoneNumber: user.phoneNumber,
              uid: user.uid
            };
            this.$store.commit('setObjTo',{what:'profile_userInfo',to:profile_userInfo});
            return this.proceedToLoginFlow(user);
          }
            
          } else {
            // No user is signed in.
            console.log('no user, please login');
            this.$f7.dialog.close();
            this.$store.commit('setWhatTo',{what:'loginOpened',to:true});
            //focus el input
            this.$$('#paxmail').find('input').focus();
          }
        });
      });
    }
  }
</script>

<style >
.loginlogo{
  width: 100%;
  max-width: 400px;
}
.grayoporte{
  color:#5c595a;
    position: relative;
    top: -58px;
    right: 30px;
}

.foneleven{
  font-size: 11px;
}
.login-screen-page, .login-screen .page {
background: url('../static/img/isoback.jpg');
background-repeat:no-repeat;
-webkit-background-size:cover;
-moz-background-size:cover;
-o-background-size:cover;
background-size:cover;
background-position:center;
}
.lesmall{
  font-size:13px;
}
.fraselogin{
  font-size: larger;
  font-weight: bolder;
}
.loginputs .item-label, .loginputs .item-floating-label{
  color:#fff!important;
}

.loginputs .item-input-focused .item-label{
  color:#36b5a9!important;
}
 .bakgris{
      background: #5b595a;
    }
        .laveldelpass .item-title.item-label{
      font-weight: bold;
    font-size: 14px;
    }
</style>